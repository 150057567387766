
































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import validation from '@/common/element-val'
import { TransactionItem } from '@/services/data'

@Component
export default class RssFeedForm extends Vue {
  $refs: {
    modelForm: ElForm;
  }

  @Prop() price: number
  model: TransactionItem = {
    SymbolId: null,
    Quantity: null,
    CostPerShare: null,
    TransDate: null,
    Commission: null,
    Type: 'Buy'
  }

  rules = {
    Quantity: validation.requiredNumberRule(),
    CostPerShare: validation.requiredNumberRule(),
    Date: validation.requiredRule()
  }

  mounted () {
    this.model.CostPerShare = this.price
  }

  @Watch('price')
  onPriceChanged (v: number) {
    this.model.CostPerShare = v
  }

  // if use @Emit,do not use this.$emit again, @Emit will return the function value to parent
  submit () {
    console.log(this.model)
    this.$refs.modelForm.validate((valid: boolean) => {
      if (valid) {
        this.$emit('submit', this.model)
      }
    })
  }
}
