import { ApiBooleanResult, ApiResult, OtmRange } from '@/services/data'
import ServiceBase from '@/services/serviceBase'

export interface OptionTarget {
  SymbolId: number;
  Target: number;
}

export interface OptionDownload {
  SymbolId: number;
  Download: boolean;
}

export interface StockOptionCheckItem {
  SymbolName: string;
  SymbolId: number;
  StockPosition: number;
  SoldCall: number;
  BuyCall: number;
  SoldPut: number;
  BuyPut: number;
  UserTarget: number;
}

export default {
  async setTargetAsync (ot: OptionTarget): Promise<ApiBooleanResult> {
    return await ServiceBase.tryPostBooleanResult('/checkList/setTarget', ot)
  },
  async getOptCheckList (): Promise<ApiResult<StockOptionCheckItem[]>> {
    return await ServiceBase.tryPostResult<StockOptionCheckItem[]>('/checkList/getOptCheckList')
  }
}
