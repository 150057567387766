<template>
  <el-form :model="model" ref="textForm" @submit.native.prevent="submit()">
    <el-form-item prop="value" :rules="rules">
      <div class="d-flex pt-3 input-group">
        <el-input class="form-input" type="text" v-model="model.value" autocomplete="off"/>
        <el-button type="primary" @click="submit()">{{buttonText || $t('button.save')}}</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  mounted () {
    this.model.value = this.value
  },
  data () {
    return {
      message: null,
      isLoading: false,
      model: {
        value: null
      }
    }
  },
  watch: {
    'model.value': {
      handler: function (val) {
        this.$emit('input', val)
      }
    },
    value (val) {
      this.model.value = val
    }
  },
  props: {
    rules: {
      type: [Object, Array],
      default: null
    },
    value: {
      type: [String, Number],
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  methods: {
    checkValidation (fun) {
      this.$refs.textForm.validate((valid) => {
        if (valid && typeof fun === 'function') {
          fun()
        }
      })
    },
    submit () {
      this.checkValidation(() => {
        this.$emit('change')
      })
    }
  }
}
</script>
