import ServiceBase from '@/services/serviceBase'
import { TransactionItem } from '@/services/data'

export default {
  async getTransactions (SymbolId: number) {
    const res = await ServiceBase.tryPostResult<TransactionItem[]>('/trans/getTransactions', { SymbolId })
    return res
  },
  async addOrUpdateTransaction (model: TransactionItem) {
    return await ServiceBase.tryPostBooleanResult('/trans/addOrUpdateTransaction', model)
  },
  async removeSymbolTran (SymbolTransId: number) {
    return await ServiceBase.tryPostBooleanResult('/trans/removeSymbolTran', { SymbolTransId })
  }
}
