import serviceBase from '@/services/serviceBase'
import { ApiBooleanResult, ApiResult } from '@/services/data'

export interface MemberUpdateModel {
  [index: string]: any;

  RoleId?: number;
  Name?: string;
  Mobile?: string;
  ChromeToken?: string;
  SimpleName?: string;
}

export interface MemberModel {
  UserId: number;
  Name: string;
  LoginId: string;
  RoleId: number;
  RoleName?: string;
  SimpleName: string;
}

export interface NewMemberModel extends MemberModel {
  Password: string;
}

export interface TradeExecutionEmailModel {
  Email: string;
}

export default {
  async getCurrentAsync (): Promise<ApiResult<MemberUpdateModel>> {
    return await serviceBase.tryPostResult<MemberUpdateModel>('/Member/getCurrent')
  },
  async updateAsync (data: MemberUpdateModel): Promise<ApiBooleanResult> {
    return await serviceBase.tryPostBooleanResult('/Member/update', data)
  },
  async registerAsync (data: NewMemberModel) {
    return await serviceBase.tryPostBooleanResult('/Member/register', data)
  },
  async addOrUpdateAsync (data: NewMemberModel) {
    return await serviceBase.tryPostBooleanResult('/Member/addOrUpdate', data)
  },
  async getTradeExecutionEmailsAsync (): Promise<ApiResult<TradeExecutionEmailModel[]>> {
    return await serviceBase.tryPostResult<TradeExecutionEmailModel[]>('/Member/getTradeExecutionEmails')
  },
  async updateTradeExecutionEmailsAsync (data: TradeExecutionEmailModel[]): Promise<ApiBooleanResult> {
    return await serviceBase.tryPostBooleanResult('/Member/updateTradeExecutionEmails', data)
  },
  async getAllAsync (Name?: string): Promise<ApiResult<MemberModel[]>> {
    return await serviceBase.tryPostResult<MemberModel[]>('/Member/getAll', {
      Name: Name || null
    })
  },
  async sendTestMsgAsync (type?: number): Promise<ApiBooleanResult> {
    return await serviceBase.tryPostBooleanResult('/Member/sendTestMsg?type=' + type)
  },
  async deleteAsync (loginId: string) {
    return await serviceBase.tryPostBooleanResult('/Member/delete', { loginId })
  }
}
